import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"

import Lightbox from "components/lightbox"
import { getProducts, getProductPath } from "utils/products"
import Demand360Logo from "images/logos/demand360-product.png"
import StreamingEconomicsLogo from "images/logos/streaming-economics.png"

const Current = styled.h1`
  ${tw`flex flex-col items-center justify-center`}

  & > div {
    ${tw`py-2 mr-3 font-display font-bold text-white text-center
         flex flex-row items-center justify-center`}

    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }
    @media (min-width: 1024px) {
      font-size: 60px;
      line-height: 79px;
    }
  }
`

const ProductLink = styled.div`
  ${tw`flex flex-row justify-between items-baseline
       text-grey-60 px-2 border-transparent mx-auto text-lg l:text-xl`}

  border-bottom-width: 2px;

  &.selected,
  &:hover {
    ${tw`text-white border-neonred-light`}
  }
`

const Product = ({ children, selected, path }) => (
  <div className="flex w-full m:w-1/2 l:w-1/3">
    {!selected && (
      <ProductLink>
        <Link to={getProductPath(path)}>{children}</Link>
      </ProductLink>
    )}
    {selected && (
      <ProductLink selected className={`${selected ? "selected" : ""}`}>
        {children}
      </ProductLink>
    )}
  </div>
)

const Container = styled.div`
  ${tw`absolute pt-0 z-20 w-full`}
  left: auto;
  right: auto;
  top: 80px;
  @media (min-width: 640px) {
    top: 110px;
  }
`

const Frame = styled.div`
  ${tw`relative pt-28 m:pt-40 mx-auto l:w-10/12 flex flex-col items-center`}
  background: #000000;
  border: 1px solid #ffffff;
  box-shadow: 4px 4px 18px 0 rgba(69, 69, 69, 0.5);
  border-radius: 5px;
`

const Button = styled.button`
  ${tw`relative mx-auto z-20 top-0 mt-4 m:mt-8 left-auto right-auto pl-6`}
  &:focus {
    outline: none;
  }
`

const HeaderLogo = styled.img`
  width: 300px;
  @media (max-width: 640px) {
      width: 200px;
    margin-left: -20px;
  }
`

const Arrow = styled.span`
  height: 1rem;
  width: 1rem;
  display: block;
  margin: 0;
  margin-left: 0.5rem;
  top: 0.1rem;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  display: inline;
  transform: scale(0.5);
  @media (min-width: 640px) {
    transform: scale(1);
    margin-left: 1rem;
    top: 0.3rem;
  }

  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 20px;
    height: 5px;
    display: block;
    transform: rotate(35deg);

    border-radius: 2px;
  }
  .left-bar:after {
    content: "";
    background-color: white;
    width: 20px;
    height: 5px;
    display: block;

    border-radius: 6px 10px 10px 6px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 13px;
    width: 20px;
    height: 5px;
    display: block;
    transform: rotate(-35deg);

    border-radius: 2px;
  }
  .right-bar:after {
    content: "";
    background-color: white;
    width: 20px;
    height: 5px;
    display: block;

    border-radius: 10px 6px 6px 10px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  &.open .left-bar:after {
    ${tw`bg-yellow`}
    transform-origin: center center;
    transform: rotate(-70deg);
  }
  &.open .right-bar:after {
    ${tw`bg-yellow`}
    transform-origin: center center;
    transform: rotate(70deg);
  }
`

const StreamingEconomicsProductSelector = ({ current }) => {
  const query = graphql`
    query StreamingEconomicsProductSelector {
      gcms {
        pages(
          where: {
            path_in: [
              "/products/content-valuation"
              "/products/streaming-metrics"
            ]
          }
          orderBy: order_ASC
        ) {
          id
          headerTitle
          path
        }
      }
    }
  `
  const data = useStaticQuery(query)
  const products = getProducts(data.gcms.pages)
  const currentProduct = products.find(({ path }) => path === current)
  const title = currentProduct?.headerTitle || ""
  const [active, activate] = useState(false)
  function toggle() {
    activate(!active)
  }

  return (
    <Container>
      <Button onClick={toggle} className="flex flex-row items-center mx-auto">
        <Current>
          <HeaderLogo src={StreamingEconomicsLogo} alt="" />
          <div className="mt-4 m:mt-2">
            {title}
            <Arrow className={active ? "open" : ""}>
              <span className="left-bar" />
              <span className="right-bar" />
            </Arrow>
          </div>
        </Current>
      </Button>
      <AnimatePresence>
        {active && (
          <motion.div
            key="product-selector-motion"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Lightbox key="product-selector-lightbox" onClick={toggle} />
            <div
              className="absolute top-0 left-0 right-0 w-11/12 mx-auto px-4"
              key="product-selector-frame"
            >
              <Frame>
                <div className="flex flex-col items-center w-full mx-auto pb-10 content-center space-y-5">
                  {products.map(({ id, path, headerTitle }) => (
                    <Product key={id} path={path} selected={current === path}>
                      {headerTitle}
                    </Product>
                  ))}
                </div>
              </Frame>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default StreamingEconomicsProductSelector
